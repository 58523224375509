<template>
  <div>
    <v-card>
      <v-toolbar dense dark color="primary">
        <v-toolbar-title><h4 class="font-weight-light">LIST OF MEMO'S</h4></v-toolbar-title>
      </v-toolbar>
      <v-layout row wrap class="align-center mx-2 mt-5">
        <v-flex xs12 md4>
          <v-select
            v-if="position!='EMPLOYEE'"
            v-model="category_id"
            dense
            outlined
            label="Category"
            :items="category_items"
            item-value="id"
            item-text="category"
            :rules="rules.combobox_rule"
            @change="selected_category"
          ></v-select>
        </v-flex>
      </v-layout>
      <v-simple-table dense class="mt-2">
        <template v-slot:default>
          <thead>
          <tr>
            <th class="text-center text-uppercase">Category</th>
            <th class="text-uppercase">Date</th>
            <th class="text-uppercase">Subject</th>
            <th class="text-center text-uppercase">Release By</th>
            <th class="text-center text-uppercase">View</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="item in data_items" :key="item.id">
            <td class="text-center">
              {{ item.category!=null?item.category.category:'GGC' }}
            </td>
            <td >
              {{ item.date }}
            </td>
            <td >
              {{ item.subject }}
            </td>
            <td class="text-center">
              {{ item.employee.last_name+', '+
              item.employee.first_name+' '+
              item.employee.middle_name}}
            </td>
            <td class="text-center">
              <v-icon
                v-if="!can_view_uploaded_cheque"
                class="mr-2"
                color="info"
                @click="view_uploaded_cheque(item)"
              >
                {{icons.mdiEyeCircle }}
              </v-icon>
              <v-progress-circular color="info" indeterminate
                                   v-else></v-progress-circular>
            </td>
          </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card>
    <v-dialog v-model="can_view_uploaded_cheque" persistent max-width="80%">
      <ViewUploadCheque
        :key="this.key"
        :title="this.subject_memo"
        :data_items="this.uploaded_data_items"

        v-on:data="selected_category"
      ></ViewUploadCheque>
      <v-btn color="error" @click="can_view_uploaded_cheque = false"> Close</v-btn>
    </v-dialog>
    <snack-bar-dialog :snackbar_flag="this.snackbar" :color="this.snackbar_color"
                      :snackbar_text="this.snackbar_text"/>
  </div>
</template>

<script>
  import {
    mdiDelete, mdiCheck
    , mdiCloseCircleOutline
    , mdiEyeCircle
  } from '@mdi/js'
  import moment from 'moment'
  import {mapActions, mapGetters} from 'vuex'
  import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'
  import ViewUploadCheque from '@/views/user_interface/memos/ViewUploadMemos'

  const initialState = () => {
    return {
      category_id: '',
      subject_memo: '',
      key: 0,
      can_view_uploaded_cheque: false,
      category_items: [],
      uploaded_data_items: [],
      data_items: [],
      total_amount: '0.00',
    }
  }
  export default {
    components: {
      snackBarDialog,
      ViewUploadCheque,
    },
    setup() {
      return {
        icons: {
          mdiDelete,
          mdiCheck,
          mdiCloseCircleOutline,
          mdiEyeCircle,
        },
        is_deleting: false,
      }
    },
    data() {
      return initialState()
    },
    mounted() {
      this.initialize_data()
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('authentication', ['position','employee_category_id']),
      ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text', 'company_logo']),
    },
    methods: {
      ...mapActions('system_data', ['change_snackbar']),
      ...mapActions('salaries_and_wages', ['initialize_salaries_employee']),
      ...mapActions('uploaded_memos', ['get_data_of_uploaded', 'get_uploaded_memos']),
     async initialize_data() {
       await this.initialize_salaries_employee()
          .then(response => {
            this.category_items = response.data[0].category
            this.category_items.splice(0, 0, {
              id: -1,
              category: 'GGC',
            })
          })
          .catch(error => {
            console.log(error)
          })

        if (this.position === 'EMPLOYEE') {
          this.category_id=await this.employee_category_id
          this.selected_category()
        }
      },
      date_format(value) {
        return moment(value)
      },
      formatPrice(value) {
        let val = (value / 1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      },
      selected_category() {
        this.can_view_uploaded_cheque = false
        this.key++
        this.get_data_of_uploaded({
          category_id: this.category_id,
        })
          .then(response => {
            this.data_items = response.data
          })
          .catch(error => {
            console.log(error)
          })
      },
      async view_uploaded_cheque(item) {
        this.can_view_uploaded_cheque = true
        this.key++
        this.uploaded_data_items = []
        this.subject_memo = item.subject
        await this.get_uploaded_memos({
          id: item.id,
        })
          .then(response => {
            this.uploaded_data_items = response.data
          })
          .catch(error => {
            console.log(error)
          })
      },
    },
  }
</script>
